import Polyglot from 'node-polyglot';
import defaults from '../../config/locales/defaults/de-renteria';
import fallbacks from '../../config/locales/zimpel/de';
import current from '../../config/locales/renteria/de';

const polyglot = new Polyglot({ phrases: defaults['de-DE-renteria'] });
polyglot.extend(fallbacks['de-DE-zimpel']);
polyglot.extend(current['de-DE-renteria']);

window.polyglot = polyglot;
